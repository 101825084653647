
import { defineComponent, PropType } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5index from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { Filter } from "@/types/filter.type";
import { getTmoa } from "@/service/tmoa.service";
import { getParameter } from "@/service/parameter.service";
import { useRoute } from "vue-router";
import { Estimation, ValidationFilter_2 } from "@/types/estimation.type";
import { getAverageEstimations, getPaginatedEstimations } from "@/service/estimation.service";
import am5lang_es_ES from "@amcharts/amcharts5/locales/es_ES";
import { getDatesWithEstimations } from "@/service/estimation.service";
import { i18n } from "@/plugins/i18n.plugin";

interface TmoaGraph {
  tmoa: string;
  count: number;
  value: number;
  name: string;
}

export default defineComponent({
  data() {
    return {
      chartId: "charttmoa",
      chartdialog: "chartdialog",
      chartParameterData: "chartParameterData",
      series: "" as unknown as am5xy.ColumnSeries,
      yAxis: "" as any,
      value2: "" as any,
      startDatePicker: null as Date | null,
      endDatePicker: null as Date | null,
      datesWithEstimations: [] as string[],
      parameterName: "" as string,
      isModalVisible: false,
      route: useRoute(),
      chartRoot: null as am5.Root | null,
      hasDataResult: null as any,
      dateKey: 0,
    };
  },
  props: {
    dataTmoaGraph: {
      type: Object as () => TmoaGraph[],
      required: true,
    },
    samplingPoint: {
      type: Object as PropType<SamplingPoint>,
      required: false,
    },
    setEstimation: {
      type: Function,
      required: true,
    },
    startDate: { type: Date, required: false, deep: true },
    endDate: { type: Date, required: false, deep: true },
    parameter: { type: Object, required: false, immediate: true, deep: true },
  },
  mounted() {
    this.$nextTick(async () => {
      const startDateStorage = sessionStorage.getItem("startDate");
      const endDateStorage = sessionStorage.getItem("endDate");

      if (startDateStorage) {
        this.startDatePicker = new Date(startDateStorage);
      } else {
        this.startDatePicker = this.startDate ?? null;
      }

      if (endDateStorage) {
        this.endDatePicker = new Date(endDateStorage);
      } else {
        this.endDatePicker = this.endDate ?? null;
      }

      this.parameterName = this.parameter?.name ?? "";
      //- Mostrar solo la gráfica cuando se ha seleccionado el parámetro
      if (this.parameterName.toLowerCase() === "matriz de agua") {
        this.fillData();
      } else {
        await this.setEstimation("", this.startDatePicker, this.endDatePicker);
        //this.createParameterTable();
        this.createParameterData();
      }
    });
  },
  methods: {
    destroyChart(rootId: string) {
      am5.array.each(am5.registry.rootElements, (root: am5.Root) => {
        if (root != undefined) {
          if (root.dom.id === rootId) {
            root.dispose();
          }
        }
      });
    },
    deleteCharts() {
      this.destroyChart("charttmoa");
      this.destroyChart("chartId");
      this.destroyChart("chartdialog");
      this.destroyChart("chartParameterData");
    },
    fillData() {
      this.deleteCharts();
      // Create root element
      let root = am5.Root.new("charttmoa");

      let myTheme = am5.Theme.new(root);

      myTheme.rule("Grid", ["base"]).setAll({
        strokeOpacity: 0.1,
      });

      // Set themes
      root.setThemes([am5themes_Animated.new(root), myTheme]);
      root.numberFormatter.set("numberFormat", "####.##");

      // Create chart
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          paddingLeft: 0,
        })
      );

      // Create axes
      let yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 30,
        minorGridEnabled: true,
      });
      yRenderer.grid.template.set("location", 1);

      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "name",
          renderer: yRenderer,
        })
      );

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          renderer: am5xy.AxisRendererX.new(root, {
            visible: true,
            strokeOpacity: 0.1,
            minGridDistance: 80,
          }),
        })
      );

      // Create series
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series 1",
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "value",
          sequencedInterpolation: true,
          categoryYField: "name",
        })
      );

      let columnTemplate = series.columns.template;

      columnTemplate.setAll({
        draggable: true,
        cursorOverStyle: "pointer",
        tooltipText: "{value} %",
        cornerRadiusBR: 10,
        cornerRadiusTR: 10,
        strokeOpacity: 0,
      });
      columnTemplate.adapters.add("fill", (fill, target) => {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      });

      columnTemplate.adapters.add("stroke", (stroke, target) => {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      });

      let minValueToShowLabel = 7;
      series.bullets.push(function (root, series, dataItem) {
        let value = dataItem.get("valueX");

        if (value && value >= minValueToShowLabel) {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{value}%",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        }
        return undefined;
      });

      columnTemplate.events.on("dragstop", () => {
        sortCategoryAxis();
      });

      // Get series item by category
      function getSeriesItem(category: any) {
        for (var i = 0; i < series.dataItems.length; i++) {
          let dataItem = series.dataItems[i];
          if (dataItem.get("categoryY") == category) {
            return dataItem;
          }
        }
      }

      // Axis sorting
      function sortCategoryAxis() {
        // Sort by value
        series.dataItems.sort(function (x, y) {
          const yGraph = y.get("graphics")?.y();
          const xGraph = x.get("graphics")?.y();
          return yGraph && xGraph ? yGraph - xGraph : 0;
        });

        let easing = am5.ease.out(am5.ease.cubic);

        // Go through each axis item
        am5.array.each(yAxis.dataItems, function (dataItem) {
          // get corresponding series item
          let seriesDataItem = getSeriesItem(dataItem.get("category"));

          if (seriesDataItem) {
            // get index of series data item
            let index = series.dataItems.indexOf(seriesDataItem);

            let column = seriesDataItem.get("graphics");

            // if (column) {
            //   // position after sorting
            //   let fy =
            //     yRenderer.positionToCoordinate(yAxis.indexToPosition(index)) - column.height() / 2;

            //   // set index to be the same as series data item index
            //   if (index != dataItem.get("index")) {
            //     dataItem.set("index", index);

            //     // current position
            //     let x = column.x();
            //     let y = column.y();

            //     column.set("dy", -(fy - y));
            //     column.set("dx", x);

            //     column.animate({ key: "dy", to: 0, duration: 600, easing: easing });
            //     column.animate({ key: "dx", to: 0, duration: 600, easing: easing });
            //   } else {
            //     column.animate({ key: "y", to: fy, duration: 600, easing: easing });
            //     column.animate({ key: "x", to: 0, duration: 600, easing: easing });
            //   }
            // }
          }
        });

        // Sort axis items by index.
        // This changes the order instantly, but as dx and dy is set and animated,
        // they keep in the same places and then animate to true positions.
        yAxis.dataItems.sort(function (x, y) {
          const xIndex: number | undefined = x.get("index");
          const yIndex: number | undefined = y.get("index");
          return xIndex && yIndex ? xIndex - yIndex : 0;
        });
      }

      this.yAxis = yAxis;
      this.series = series;

      if (this.dataTmoaGraph) {
        this.yAxis.data.setAll(this.dataTmoaGraph as TmoaGraph[]);
        this.series.data.setAll(this.dataTmoaGraph as TmoaGraph[]);
      }

      // Make stuff animate on load
      this.series.appear(1000);
      chart.appear(1000, 100);
    },
    goToIntegradas() {
      if (this.samplingPoint?.id) {
        if (this.startDate) {
          sessionStorage.setItem("startDate", this.startDate.toString());
        }
        if (this.endDate) {
          sessionStorage.setItem("endDate", this.endDate.toString());
        }
        this.$router.push({
          path: `/support/import/${this.samplingPoint.id}`,
          query: { samplingPoint: this.samplingPoint.id, integradas: "true" },
        });
      }
    },

    goToTMOA() {
      const containerId = this.$route.params.containerid;
      if (this.samplingPoint?.id) {
        this.$router.push({
          path: `/support/samplingPoints/${containerId}/tmoa`,
          query: { samplingPoint: this.samplingPoint.id },
        });
      }
    },
    goToReport() {
      if (this.samplingPoint?.id) {
        this.$router.push({
          path: `/support/report`,
          query: {
            samplingPoint: this.samplingPoint.id,
            parameter: this.parameterName,
          },
        });
      }
    },

    /**
     * Cambiar el btn 24 horas que por defecto está en azul
     */
    removeBlueClass() {
      var blueBtn = document.getElementsByClassName("blue");

      if (blueBtn[0]) {
        blueBtn[0].className = blueBtn[0].className.replace("blue", "");
      }
    },
    addBlueClass(idBtn: string) {
      const btnDate = document.getElementById(idBtn);
      btnDate?.classList.add("blue");
    },
    async clickBtn(type: string) {
      this.removeBlueClass();
      this.addBlueClass(type);

      await this.setEstimation(type);
      await this.hasData();

      if (this.parameterName.toLowerCase() === "matriz de agua") {
        this.fillData();
      } else {
        //this.createParameterTable();
        this.createParameterData();
      }
      // this.removeBlueClass();
      // this.addBlueClass(type);
      // this.setEstimation(type);
    },
    dateFuture(date: Date) {
      return date.getTime() > Date.now();
    },
    disabledData(date: Date) {
      return !this.datesWithEstimations.includes(date.toDateString());
    },
    openDialog() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    parseDate(dateString: string) {
      const [day, month, year, ...time] = dateString.split(/[/ :]/);
      if (time.length === 0) {
        return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
      } else {
        const [hour, minute, second] = time.map(Number);
        return new Date(
          Number(year),
          Number(month) - 1,
          Number(day),
          hour,
          minute,
          second
        ).getTime();
      }
    },
    formatTime(date: Date | null): string | null {
      if (!date) {
        return null;
      }
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    formatDate(dateString: string | number | Date | undefined) {
      if (dateString === undefined) {
        return;
      }
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    async createTable() {
      this.destroyChart("chartdialog");
      this.destroyChart("chartId");
      this.destroyChart("charttmoa");
      this.hasData();
      if (this.hasDataResult === null && !this.hasDataResult) {
        return;
      }
      let root = am5.Root.new("chartdialog");
      root.numberFormatter.set("numberFormat", "####.##");

      let filter: Filter = {
        fields: {
          "parameter.name": {
            operator: "equal",
            value: this.parameterName,
          },
        },
      };

      let parameterData = (await getParameter(undefined, undefined, filter))?.data;

      const validationFilter = {
        samplingPointId: Number(this.route.query.samplingPoint),
        parameterId: Number(parameterData[0].id),
        date: [this.startDatePicker, this.endDatePicker],
      } as unknown as ValidationFilter_2;

      let estimation_history = await getAverageEstimations(
        validationFilter,
        this.formatTime(this.startDatePicker) || "",
        this.formatTime(this.endDatePicker) || ""
      );

      let formattedData = estimation_history.map((estimation: Estimation) => ({
        date: new Date(estimation.dateTime ?? new Date()).getTime(),
        value: estimation.value?.toFixed(2),
      }));

      const myTheme = am5.Theme.new(root);
      myTheme.rule("AxisLabel", ["minor"]).setAll({ dy: 1 });
      myTheme.rule("Grid", ["minor"]).setAll({ strokeOpacity: 0.08 });

      root.setThemes([am5themes_Animated.new(root), myTheme]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          paddingLeft: 0,
        })
      );

      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "zoomX",
        })
      );
      cursor.lineY.set("visible", false);

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 200,
            minorLabelsEnabled: true,
          }),
          tooltip: am5.Tooltip.new(root, {}),
          extraMin: 0.01,
          extraMax: 0.01,
        })
      );

      xAxis.set("minorDateFormats", {
        day: "dd",
        month: "MM",
      });

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Estimaciones de Aquacorp",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        })
      );

      series.bullets.push(function () {
        let bulletCircle = am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
        });
        return am5.Bullet.new(root, {
          sprite: bulletCircle,
        });
      });

      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        })
      );
      chart.set(
        "scrollbarY",
        am5.Scrollbar.new(root, {
          orientation: "vertical",
        })
      );

      series.data.setAll(formattedData);

      // let legend = chart.children.push(am5.Legend.new(root, {
      //     centerX: am5.p50,
      //     x: am5.p50,
      //     centerY: am5.p102,
      //     y: am5.p100
      // }));
      // legend.data.setAll(chart.series.values);

      series.appear(1000);
      chart.appear(1000, 100);
    },
    async createParameterTable() {
      // Obtener id parametro
      let filter: Filter = {
        fields: {
          "parameter.name": {
            operator: "equal",
            value: this.parameterName,
          },
        },
      };

      let parameterData = (await getParameter(undefined, undefined, filter))?.data;

      if (!parameterData || parameterData.length === 0) {
        console.error("No se encontró ningún dato de parámetro.");
        return;
      }

      const validationFilter = {
        samplingPointId: Number(this.route.query.samplingPoint),
        parameterId: Number(parameterData[0].id),
        date: [this.startDatePicker, this.endDatePicker],
      } as unknown as ValidationFilter_2;

      let estimation_history = await getPaginatedEstimations(validationFilter);

      // if (!estimation_history || estimation_history.length === 0) {
      //   console.error("No se encontró ninguna estimación.");
      //   return;
      // }

      let formattedData = estimation_history.map((estimation: Estimation) => ({
        date: new Date(estimation.dateTime ?? new Date()).getTime(),
        value: estimation.value ?? 0,
      }));

      // Ordenar los datos de menor a mayor
      formattedData.sort((a, b) => (a.value ?? 0) - (b.value ?? 0));

      // Calcular la media de los valores
      let total = formattedData.reduce((sum, data) => sum + (data.value ?? 0), 0);
      let meanValue = total / formattedData.length;

      // Calcular el valor mínimo y máximo
      let minValue =
        formattedData.length > 0 ? Math.min(...formattedData.map((data) => data.value ?? 0)) : 0;
      let maxValue =
        formattedData.length > 0 ? Math.max(...formattedData.map((data) => data.value ?? 0)) : 0;

      this.deleteCharts();

      // Crear el elemento root
      let root = am5.Root.new("chartParameterData");
      root.numberFormatter.set("numberFormat", "####.##");

      const myTheme = am5.Theme.new(root);

      myTheme.rule("AxisLabel", ["minor"]).setAll({
        dy: 1,
      });

      myTheme.rule("Grid", ["minor"]).setAll({
        strokeOpacity: 0.08,
      });

      // Establecer temas
      root.setThemes([am5themes_Animated.new(root), myTheme]);

      // Crear el gráfico
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none", // Desactivar el paneo con la rueda del mouse
          wheelY: "none", // Desactivar el zoom con la rueda del mouse
        })
      );

      // Añadir cursor
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        })
      );

      //- Quitar botón del zoom
      chart.zoomOutButton.set("forceHidden", true);

      cursor.lineY.set("visible", false);

      // Crear ejes
      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            visible: false,
          }),
          extraMin: 0.01,
        })
      );

      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });

      let yRenderer = am5xy.AxisRendererY.new(root, {});
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer,
        })
      );

      // Añadir serie
      let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "index",
          tension: 0.1,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}" + " " + this.parameter?.unit,
          }),
        })
      );
      // let series = chart.series.push(
      //   am5xy.SmoothedXLineSeries.new(root, {
      //     name: "Series",
      //     xAxis: xAxis,
      //     yAxis: yAxis,
      //     valueYField: "value",
      //     valueXField: "index",
      //     tension: 0.1,
      //     tooltip: am5.Tooltip.new(root, {
      //       labelText: "[bold]{valueX.formatDate('dd/MM/yyyy')}[/]\n[bold]{valueY} " + this.parameter?.unit,
      //     }),
      //   })
      // );

      // Añadir línea horizontal la media
      let meanLine = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: meanValue ?? 0,
          endValue: meanValue ?? 0,
        })
      );

      if (meanValue) {
        const grid = meanLine.get("grid");

        if (grid) {
          grid.setAll({
            stroke: am5.color(0x0a0a0a),
            strokeWidth: 2,
            strokeDasharray: [5, 5],
          });
        }
      }

      // Añadir bullets para el valor mínimo y máximo
      function addBullet(value: number, color: am5.Color, label: string, index: number) {
        let bulletSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: label,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "index",
          })
        );

        bulletSeries.data.setAll([{ value, index }]);

        // Añadir el bullet
        bulletSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 3,
              fill: color,
            }),
          });
        });

        return bulletSeries;
      }

      // Añadir bullets para el valor mínimo y máximo
      function addInvisibleBullet(value: number, color: am5.Color, label: string, index: number) {
        let bulletSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: label,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "index",
          })
        );

        bulletSeries.data.setAll([{ value, index }]);

        // Añadir el bullet
        bulletSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 3,
              fill: am5.color(0x000000),
              opacity: 0,
              cursorOverStyle: "pointer",
            }),
          });
        });

        return bulletSeries;
      }

      // Añadir bullets para el valor mínimo y máximo
      let maxIndex = formattedData.findIndex((data) => data.value === maxValue);
      minValue
        ? addBullet(minValue, am5.color(0x50c878), "Mínimo: " + minValue.toFixed(2), 0)
        : addInvisibleBullet(minValue, am5.color(0x50c878), "Mínimo: " + minValue.toFixed(2), 0);
      maxValue
        ? addBullet(maxValue, am5.color(0xffa500), "Máximo: " + maxValue.toFixed(2), maxIndex)
        : addInvisibleBullet(maxValue, am5.color(0xffa500), "Máximo: " + maxValue.toFixed(2), 10);

      // Animar la carga
      series.appear(1000);
      chart.appear(1000, 100);

      // Crear leyenda
      let legendContainer = root.container.children.push(
        am5.Container.new(root, {
          layout: root.horizontalLayout,
          x: am5.percent(50),
          centerX: am5.percent(50),
          y: am5.percent(103),
          centerY: am5.percent(103),
          paddingTop: 20,
          paddingLeft: 20,
        })
      );

      function createLegendItem(name: string, color: am5.Color) {
        let legendItem = am5.Container.new(root, {
          layout: root.horizontalLayout,
          marginBottom: 0,
          centerY: am5.percent(50),
        });

        let colorCircle = am5.Circle.new(root, {
          radius: 3,
          fill: color,
          // marginRight: 1,
          centerY: am5.percent(50),
        });

        let label = am5.Label.new(root, {
          text: name,
          fontSize: 14,
          // marginRight: 5,
          centerY: am5.percent(50),
        });

        // Añadir al contenedor de leyenda
        legendItem.children.push(colorCircle);
        legendItem.children.push(label);

        return legendItem;
      }
      function createLegendItemLine(name: string, color: am5.Color) {
        let legendItem = am5.Container.new(root, {
          layout: root.horizontalLayout,
          marginBottom: 0,
          centerY: am5.percent(50),
        });

        let colorLine1 = am5.Rectangle.new(root, {
          width: 5,
          height: 2,
          fill: color,
          marginRight: 3,
          centerY: am5.percent(50),
        });

        let colorLine2 = am5.Rectangle.new(root, {
          width: 5,
          height: 2,
          fill: color,
          marginRight: 3,
          centerY: am5.percent(50),
        });

        let label = am5.Label.new(root, {
          text: name,
          fontSize: 14,
          marginRight: 5,
          centerY: am5.percent(50),
        });

        legendItem.children.push(colorLine1);
        legendItem.children.push(colorLine2);
        legendItem.children.push(label);

        return legendItem;
      }

      legendContainer.children.push(
        createLegendItem(
          `Mín: ${minValue ? minValue.toFixed(2) : "-"} ${this.parameter?.unit ?? "-"}`,
          am5.color(0x50c878)
        )
      );
      legendContainer.children.push(
        createLegendItem(
          `Max: ${maxValue ? maxValue.toFixed(2) : "-"} ${this.parameter?.unit ?? "-"}`,
          am5.color(0xffa500)
        )
      );
      legendContainer.children.push(
        createLegendItemLine(
          `Media: ${meanValue ? meanValue.toFixed(2) : "-"} ${this.parameter?.unit ?? "-"}`,
          am5.color(0x808080)
        )
      );

      // Añadir los datos a la serie
      if (formattedData.length > 0) {
        series.data.setAll(formattedData.map((data, index) => ({ ...data, index })));
      } else {
        const dataDefault = [
          {
            date: this.startDatePicker,
            value: null,
          },

          {
            date: this.endDatePicker,
            value: null,
          },
        ];

        series.data.setAll(dataDefault.map((data, index) => ({ ...data, index })));
      }
    },
    async createParameterData() {
      let filter: Filter = {
        fields: {
          "parameter.name": {
            operator: "equal",
            value: this.parameterName,
          },
        },
      };

      let parameterData = (await getParameter(undefined, undefined, filter))?.data;

      const validationFilter = {
        samplingPointId: Number(this.route.query.samplingPoint),
        parameterId: Number(parameterData[0].id),
        date: [this.startDatePicker, this.endDatePicker],
      } as unknown as ValidationFilter_2;

      let estimation_history = await getAverageEstimations(
        validationFilter,
        this.formatTime(this.startDatePicker) || "",
        this.formatTime(this.endDatePicker) || ""
      );

      let formattedData = estimation_history.map((estimation: Estimation) => ({
        date: new Date(estimation.dateTime ?? new Date()).getTime(),
        value: estimation.value,
      }));

      if (formattedData.length === 0) {
        return;
      }

      this.deleteCharts();
      let root = am5.Root.new("chartParameterData");
      root.locale = am5lang_es_ES;
      // root.numberFormatter.set("numberFormat", "####.##");

      let minValue = Math.min(...formattedData.map((data) => data.value ?? 0));
      let maxValue = Math.max(...formattedData.map((data) => data.value ?? 0));

      let total = formattedData.reduce((sum, data) => sum + (data.value ?? 0), 0);
      let meanValue = total / formattedData.length;

      const myTheme = am5.Theme.new(root);
      myTheme.rule("AxisLabel", ["minor"]).setAll({ dy: 1 });
      myTheme.rule("Grid", ["minor"]).setAll({ strokeOpacity: 0.08 });

      root.setThemes([am5themes_Animated.new(root), myTheme]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          paddingLeft: 0,
        })
      );

      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          // behavior: "zoomX",
        })
      );
      cursor.lineY.set("visible", false);

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          dateFormats: {
            day: "dd",
            week: "MMM yyyy",
            month: "MMM yyyy",
            year: "yyyy",
          },
          periodChangeDateFormats: {
            day: "dd",
            week: "MMMM yyyy",
            month: "MMMM yyyy",
            year: "yyyy",
          },
          gridIntervals: [
            { timeUnit: "day", count: 1 },
            { timeUnit: "week", count: 1 },
            { timeUnit: "month", count: 1 },
            { timeUnit: "year", count: 1 },
          ],
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 80,
          }),
          tooltip: am5.Tooltip.new(root, {}),
          extraMin: 0.01,
          extraMax: 0.01,
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Estimaciones de Aquacorp",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        })
      );

      series.data.setAll(formattedData);

      interface DataContext {
        value: number;
      }

      series.bullets.push(function (
        root,
        series,
        dataItem: am5.DataItem<am5xy.ILineSeriesDataItem>
      ) {
        let dataContext = dataItem.dataContext as DataContext;
        let value = dataContext.value;
        let color;

        if (value === minValue) {
          color = am5.color(0x50c878);
        } else if (value === maxValue) {
          color = am5.color(0xffa500);
        } else {
          color = series.get("fill");
        }

        let bulletCircle = am5.Circle.new(root, {
          radius: 3,
          fill: color,
        });

        return am5.Bullet.new(root, {
          sprite: bulletCircle,
        });
      });

      let meanLine = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: meanValue ?? 0,
          endValue: meanValue ?? 0,
        })
      );

      const grid = meanLine.get("grid");
      if (grid) {
        grid.setAll({
          stroke: am5.color(0x303030),
          strokeWidth: 2,
          strokeDasharray: [5, 5],
        });
      }

      let legendContainer = root.container.children.push(
        am5.Container.new(root, {
          layout: root.horizontalLayout,
          x: am5.percent(50),
          centerX: am5.percent(50),
          y: am5.percent(103),
          centerY: am5.percent(103),
          paddingTop: 20,
          paddingLeft: 20,
        })
      );

      function createLegendItem(name: string, color: am5.Color) {
        let legendItem = am5.Container.new(root, {
          layout: root.horizontalLayout,
          marginBottom: 0,
          centerY: am5.percent(50),
        });

        let colorCircle = am5.Circle.new(root, {
          radius: 3,
          fill: color,
          centerY: am5.percent(50),
        });

        let label = am5.Label.new(root, {
          text: name,
          fontSize: 14,
          centerY: am5.percent(50),
        });

        legendItem.children.push(colorCircle);
        legendItem.children.push(label);

        return legendItem;
      }

      function createLegendItemLine(name: string, color: am5.Color) {
        let legendItem = am5.Container.new(root, {
          layout: root.horizontalLayout,
          marginBottom: 0,
          centerY: am5.percent(50),
        });

        let colorLine1 = am5.Rectangle.new(root, {
          width: 10,
          height: 2,
          fill: color,
          marginRight: 3,
          centerY: am5.percent(50),
        });

        let colorLine2 = am5.Rectangle.new(root, {
          width: 10,
          height: 2,
          fill: color,
          marginRight: 3,
          centerY: am5.percent(50),
        });

        let label = am5.Label.new(root, {
          text: name,
          fontSize: 14,
          marginRight: 5,
          centerY: am5.percent(50),
        });

        legendItem.children.push(colorLine1);
        legendItem.children.push(colorLine2);
        legendItem.children.push(label);

        return legendItem;
      }

      // i18n.global.n para formatear según idioma de la aplicación
      legendContainer.children.push(
        createLegendItem(
          `Mín: ${minValue ? i18n.global.n(minValue, "fixed") : "-"} ${
            this.parameter?.unit ?? "-"
          }`,
          am5.color(0x50c878)
        )
      );

      legendContainer.children.push(
        createLegendItem(
          `Max: ${maxValue ? i18n.global.n(maxValue, "fixed") : "-"} ${
            this.parameter?.unit ?? "-"
          }`,
          am5.color(0xffa500)
        )
      );
      legendContainer.children.push(
        createLegendItemLine(
          `Media: ${meanValue ? i18n.global.n(meanValue, "fixed") : "-"} ${
            this.parameter?.unit ?? "-"
          }`,
          am5.color(0x808080)
        )
      );

      series.appear(1000);
      chart.appear(1000, 100);
    },
    async hasData() {
      let filter: Filter = {
        fields: {
          "parameter.name": {
            operator: "equal",
            value: this.parameterName,
          },
        },
      };

      let parameterData = (await getParameter(undefined, undefined, filter))?.data;

      const validationFilter = {
        samplingPointId: Number(this.route.query.samplingPoint),
        parameterId: Number(parameterData[0].id),
        date: [this.startDatePicker, this.endDatePicker],
      } as unknown as ValidationFilter_2;

      let estimation_history = await getAverageEstimations(
        validationFilter,
        this.formatTime(this.startDatePicker) || "",
        this.formatTime(this.endDatePicker) || ""
      );
      this.hasDataResult = true;
      if (!estimation_history || estimation_history.length === 0) {
        this.hasDataResult = null;
      }
    },
    async onDateChange() {
      await this.setEstimation("", this.startDatePicker, this.endDatePicker);
      await this.hasData();
      await this.getMarkedDates();

      if (this.parameterName.toLowerCase() === "matriz de agua") {
        this.fillData();
      } else {
        //this.createParameterTable();
        this.createParameterData();
      }
    },
    async getMarkedDates() {
      if (this.parameterName && this.samplingPoint?.id) {
        await getDatesWithEstimations([this.samplingPoint.id], [this.parameter?.id])
          .then((response) => {
            this.datesWithEstimations = response.map((date) =>
              new Date(date.image_date).toDateString()
            );
            this.dateKey++;
          })
          .catch((error) => console.error(error));
      }
    },
    hasEstimatedData(day: Date) {
      return this.datesWithEstimations.includes(day.toDateString());
    },
  },
  watch: {
    dataTmoaGraph: function () {
      //- Mostrar solo la gráfica cuando se ha seleccionado el parámetro
      if (
        this.dataTmoaGraph &&
        this.dataTmoaGraph.length > 0 &&
        this.parameterName.toLowerCase() === "matriz de agua"
      ) {
        this.fillData();
      }
    },
    startDate: async function () {
      this.startDatePicker = this.startDate ?? null;
    },
    parameter: async function () {
      this.parameterName = this.parameter?.name ?? "";
      this.getMarkedDates();
      this.hasData();
      this.deleteCharts();
      //this.createParameterTable();
      this.createParameterData();
    },
  },
});
