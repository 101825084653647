import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sampling_point_tmoa_detail_component = _resolveComponent("sampling-point-tmoa-detail-component")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_sampling_point_real_time_data_component = _resolveComponent("sampling-point-real-time-data-component")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_sampling_point_chart_component = _resolveComponent("sampling-point-chart-component")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, {
      gutter: 10,
      class: "components-up"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_sampling_point_tmoa_detail_component, {
              startDate: _ctx.startDateCalculate,
              endDate: _ctx.endDateCalculate,
              dataTmoaGraph: _ctx.values,
              samplingPoint: _ctx.samplingPoint,
              setEstimation: _ctx.modifyChart,
              parameter: _ctx.parameter
            }, null, 8, ["startDate", "endDate", "dataTmoaGraph", "samplingPoint", "setEstimation", "parameter"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 14 }, {
          default: _withCtx(() => [
            _createVNode(_component_sampling_point_real_time_data_component, {
              defaultParameter: _ctx.parameter,
              samplingPoint: _ctx.samplingPoint,
              image: _ctx.lastImage,
              data: _ctx.visibleParameters,
              onParameterClick: _ctx.changeParameter,
              class: "component-up"
            }, null, 8, ["defaultParameter", "samplingPoint", "image", "data", "onParameterClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { class: "components-down" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_sampling_point_chart_component, {
              estimation: _ctx.dataChart,
              tmoa: _ctx.dataChartTmoa,
              samplingPoint: _ctx.samplingPoint,
              parameter: _ctx.parameter,
              class: "component-down"
            }, null, 8, ["estimation", "tmoa", "samplingPoint", "parameter"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}