import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34c19d8d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-images" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sampling_point_image_component = _resolveComponent("sampling-point-image-component")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_observer_component = _resolveComponent("observer-component")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": true,
    "destroy-on-close": "",
    title: _ctx.title
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("btn.close")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_sampling_point_image_component, {
          images: _ctx.images,
          colourCode: _ctx.colourCode,
          title: _ctx.getSamplingPointName()
        }, null, 8, ["images", "colourCode", "title"]),
        (_ctx.hasMore)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_el_button, {
                primary: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMore()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("btn.showMore")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_observer_component, { onIntersect: _ctx.showMore }, null, 8, ["onIntersect"])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}