
import { defineComponent, ref, PropType, watch } from "vue";
import { ElTable } from "element-plus";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { Estimation } from "@/types/estimation.type";
import { Parameter } from "@/types/parameter.type";
import { formatParameter } from "@/utils/parameter.util";
import SamplingPointImageComponent from "./SamplingPointImage.component.vue";
import SamplingPointImageModalComponent from "./SamplingPointImageModal.component.vue";
import ModalInfoEstimationsYesterday from "@/components/modalInfoEstimationsYesterday.component.vue";

interface ParameterTable extends Estimation {
  name?: string;
  colour?: string;
  tmoa?: string;
}

export default defineComponent({
  components: {
    SamplingPointImageComponent,
    SamplingPointImageModalComponent,
    ModalInfoEstimationsYesterday,
  },
  props: {
    defaultParameter: {
      type: Object,
      required: false,
    },
    samplingPoint: {
      type: Object as PropType<SamplingPoint>,
      required: false,
    },
    image: {
      type: Object,
      required: false,
    },
    data: {
      type: Object as PropType<ParameterTable[]>,
      required: false,
    },
    onParameterClick: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const format = formatParameter;
    return { format };
  },
  data() {
    return {
      entorno: false,
      selectedParameter: {} as Parameter,
      singleTableRef: ref<InstanceType<typeof ElTable>>(),
      currentRow: ref(),
      isModalImageVisible: false,
      isModalEstimationsYesterdayVisible: false,
      datosParametros: {} as any,
      dialogInfoTrendVisible: false,
    };
  },

  async mounted() {
    // Control de entorno
    this.entorno = process.env.NODE_ENV === "pre" || process.env.NODE_ENV === "development";
    // this.selectedParameter = Object.assign({} as Parameter, this.orderParameterByName(this.data)[0]);
    this.selectedParameter = Object.assign({} as Parameter, this.defaultParameter);
    // console.log(this.defaultParameter)
  },

  methods: {
    visualiceModalEstimtionYesterday() {
      this.isModalEstimationsYesterdayVisible = true;
      this.datosParametros = this.data;
    },
    closeModalImage() {
      this.isModalImageVisible = false;
    },
    seeImages() {
      if (this.samplingPoint?.id) {
        this.isModalImageVisible = true;
      }
    },
    dialogInfoTrendOpen() {
      this.dialogInfoTrendVisible = true;
    },

    goToTMOA() {
      if (this.samplingPoint?.id) {
        this.$router.push({
          path: `/support/samplingPoints/tmoa/${this.samplingPoint.id}`,
          query: { samplingPoint: this.samplingPoint.id },
        });
      }
    },

    tableRowClassName(obj: { row: ParameterTable; rowIndex: number }) {
      if (obj.row.parameter === this.defaultParameter) {
        // if (obj.row.parameter === this.orderParameterByName(this.data)[0].parameter) {
        return "selected-row";
      } else {
        return "";
      }
    },

    onClick(parameter: Parameter | undefined) {
      var row = document.getElementsByClassName("selected-row");

      if (row[0]) {
        row[0].className = row[0].className.replace("selected-row", "");
      }

      this.onParameterClick(parameter);
    },

    orderParameterByName(dataArray: any[] | undefined) {
      if (!dataArray) {
        console.log("Sin Datos");
        return [];
      }

      const order = [
        "Turbidez",
        "Sólidos en Suspensión",
        "Sólidos disueltos",
        "MES",
        "Conductividad",
        "REDOX",
        "DQO",
        "DBO5 | DBO",
        "Carbono Orgánico Disuelto",
        "Carbono Orgánico Total",
        "Nitrógeno Total",
        "Nitrato",
        "Oxígeno Disuelto",
        "pH",
        "Fósforo",
        "Fosfato",
        "Ortofosfato",
        "Amonio",
        "Hierro",
        "Manganeso",
        "Aceites y grasas",
        "Espumas",
        "Lentejas",
        "Retorno",
        "Color",
        "Algas verdeazuladas",
        "Algas verdes",
        "Clorofila A (algas verdeazuladas)",
        "Clorofila A (algas verdes)",
        "Clorofila A (diatomeas)",
        "Clorofila A (cryptophytas)",
        "Clorofila A total",
        "Cryptophytas (control de algas superficie)",
        "Diatomeas (control de algas superficie)",
        "Total algas (control de algas superficie)",
      ];

      return dataArray.sort((a, b) => {
        const nameA = a.parameter?.name?.toLowerCase();
        const nameB = b.parameter?.name?.toLowerCase();

        if (!nameA || !nameB) {
          return 0;
        }

        const indexA = order.findIndex((item) => item.toLowerCase() === nameA);
        const indexB = order.findIndex((item) => item.toLowerCase() === nameB);

        if (indexA === -1 && indexB === -1) {
          return 0;
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }

        return indexA - indexB;
      });
    },
  },
});
