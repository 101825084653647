import { Tmoa } from "./../types/tmoa.type";

export const sortByType = (arrayTmoa: Tmoa[]): Tmoa[] => {
  return arrayTmoa.sort((a: Tmoa, b: Tmoa) => {
    // Extraer el número del nombre "TMOA X"
    if (a.type !== undefined && b.type !== undefined) {
      const numeroA = parseInt(a.type.replace("TMOA ", ""));
      const numeroB = parseInt(b.type.replace("TMOA ", ""));

      // Comparar los números y devolver el resultado
      return numeroA - numeroB;
    } else {
      return 0;
    }
  });
};

export const sortByTypeReverse = (arrayTmoa: Tmoa[]): Tmoa[] => {
  return arrayTmoa.sort((a: Tmoa, b: Tmoa) => {
    // Extraer el número del nombre "TMOA X"
    if (a.type !== undefined && b.type !== undefined) {
      const numeroA = parseInt(a.type.replace("TMOA ", ""));
      const numeroB = parseInt(b.type.replace("TMOA ", ""));

      // Comparar los números y devolver el resultado
      return numeroB - numeroA;
    } else {
      return 0;
    }
  });
};
