
import { defineComponent, initCustomFormatter } from "vue";
import SamplingPointRealTimeDataComponent from "@/components/samplingPoints/SamplingPointRealTimeDataTable.component.vue";
import SamplingPointChartComponent from "@/components/samplingPoints/SamplingPointChart.component.vue";
import SamplingPointTmoaDetailComponent from "@/components/samplingPoints/SamplingPointTmoaDetail.component.vue";

import { Estimation, Trend, ValidationFilter_2 } from "@/types/estimation.type";
import { getSamplingPointById } from "@/service/samplingpoint.service";
import { getParameter } from "@/service/parameter.service";
import { getLastImageWithParameters } from "@/service/image.service";
import { getTmoa } from "@/service/tmoa.service";
import { Filter } from "@/types/filter.type";
import { Parameter } from "@/types/parameter.type";
import { SamplingPoint } from "@/types/samplingpoint.type";
import { Image } from "@/types/Image.type";
import { Tmoa } from "@/types/tmoa.type";
import { sortByCode } from "@/utils/parameter.util";
import { StatisticTable } from "@/types/table.type";
import { getPaginatedEstimations } from "@/service/estimation.service";
import moment from "moment";
import { sortByType, sortByTypeReverse } from "@/utils/tmoa.util";
import * as am5 from "@amcharts/amcharts5";
import { Color } from "@amcharts/amcharts5/.internal/core/util/Color";
import { removeDuplicate } from "@/utils/estimation.util";

interface ParameterTable extends Estimation {
  name?: string;
  colour?: string;
  tmoa?: string;
}

interface DataChart {
  dateTime: number;
  value: number;
  name: string;
  unit?: string;
  fileName?: string;
  bulletSettings?: { fill: Color; opacity?: number };
  strokeSettings?: { stroke: Color };
}

interface TmoaGraph {
  tmoa: string;
  name: string;
  count: number;
  value: number;
}

export default defineComponent({
  components: {
    SamplingPointRealTimeDataComponent,
    SamplingPointChartComponent,
    SamplingPointTmoaDetailComponent,
  },
  data() {
    return {
      dataChart: undefined as DataChart[] | undefined,
      dataChartTmoa: [] as DataChart[] | undefined,
      values: [] as TmoaGraph[],
      externalSamplingPoint: undefined as SamplingPoint | undefined,
      samplingPoint: undefined as SamplingPoint | undefined,
      parameter: undefined as Parameter | undefined,
      lastImage: undefined as Image | undefined,
      visibleParameters: undefined as ParameterTable[] | undefined,
      statistics: [] as StatisticTable[] | undefined,
      statisticsCopy: [] as StatisticTable[] | undefined,
      startDateCalculate: new Date(),
      endDateCalculate: new Date(),
      visible: false,
      index: -2 as number,
      // TMOAs del punto de monitorización
      tmoas: [] as Tmoa[],
    };
  },
  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.calculateRangeDay(-24, "h");
      if (this.$route.query.samplingPoint) {
        this.samplingPoint = await getSamplingPointById(
          parseInt(this.$route.query.samplingPoint as string)
        );
      }

      await this.updateLastImage();

      //this.changeInitialParameter();
      await this.createParameterTable();
    },
    /**
     * Calculate by default 24 hours
     */
    calculateRangeDay(
      time: moment.DurationInputArg1,
      unit: moment.unitOfTime.DurationConstructor | undefined
    ) {
      this.endDateCalculate = new Date();
      this.startDateCalculate = moment(this.endDateCalculate).add(time, unit).toDate();
    },

    async changeParameter(parameter: Parameter) {
      this.parameter = parameter;
    },

    async createParameterTable() {
      const tmoas = sortByTypeReverse([...(await this.getTmoaBySamplingPoint())]);

      this.visibleParameters = this.samplingPoint?.parameters
        ?.filter(this.filterByParameterVisibility)
        .map(this.mapParameterToResult) as ParameterTable[];

      const parameters = this.lastImage?.imageEstimation;

      sortByCode(this.visibleParameters);
      this.orderParameterByName(this.visibleParameters);
      if (this.visibleParameters) {
        if (parameters) {
          const index = this.visibleParameters.findIndex(
            (data: any) => data.parameter.name?.toLowerCase() === "matriz de agua"
          );

          for (const p of this.visibleParameters) {
            if (p.parameter && p.parameter.name) {
              const data = p.parameter?.code ? this.isInclude(p.parameter?.code) : undefined;

              p.value = data?.value;
              if (p.parameter?.code === "TMOA" && p.value && tmoas && tmoas.length > 0) {
                let tmoaObj = tmoas.find((objeto) => objeto.value == (p.value as number));
                if (tmoaObj && tmoaObj.name) {
                  p.tmoa = `${tmoaObj?.name}`;
                } else {
                  p.tmoa = `${data?.value}`;
                }
              }
              p.trend = data?.trend;
              p.percent = data?.percent;
            }
          }
        }
        this.parameter = this.visibleParameters[0]?.parameter;
      }
    },
    orderParameterByName(dataArray: any[] | undefined) {
      if (!dataArray) {
        console.log("Sin Datos");
        return [];
      }

      const order = [
        "Turbidez",
        "Sólidos en Suspensión",
        "Sólidos disueltos",
        "MES",
        "Conductividad",
        "REDOX",
        "DQO",
        "DBO5 | DBO",
        "Carbono Orgánico Disuelto",
        "Carbono Orgánico Total",
        "Nitrógeno Total",
        "Nitrato",
        "Oxígeno Disuelto",
        "pH",
        "Fósforo",
        "Fosfato",
        "Ortofosfato",
        "Amonio",
        "Hierro",
        "Manganeso",
        "Aceites y grasas",
        "Espumas",
        "Lentejas",
        "Retorno",
        "Color",
        "Algas verdeazuladas",
        "Algas verdes",
        "Clorofila A (algas verdeazuladas)",
        "Clorofila A (algas verdes)",
        "Clorofila A (diatomeas)",
        "Clorofila A (cryptophytas)",
        "Clorofila A total",
        "Cryptophytas (control de algas superficie)",
        "Diatomeas (control de algas superficie)",
        "Total algas (control de algas superficie)",
      ];
      const imageEstimation = removeDuplicate(dataArray as Estimation[]);

      return dataArray.sort((a, b) => {
        const nameA = a.parameter?.name?.toLowerCase();
        const nameB = b.parameter?.name?.toLowerCase();

        if (!nameA || !nameB) {
          return 0;
        }

        const indexA = order.findIndex((item) => item.toLowerCase() === nameA);
        const indexB = order.findIndex((item) => item.toLowerCase() === nameB);

        if (indexA === -1 && indexB === -1) {
          return 0;
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }

        return indexA - indexB;
      });
    },

    async modifyChart(type: string, startDate: Date | undefined, endDate: Date | undefined) {
      if (type === "" && startDate && endDate) {
        this.startDateCalculate = startDate;
        this.endDateCalculate = endDate;
        await this.getDataChart();
        await this.generateDataProbabilityGraph();
      } else {
        switch (type) {
          case "btnDay":
            this.calculateRangeDay(-24, "h");
            await this.getDataChart();
            await this.generateDataProbabilityGraph();

            break;

          case "btnWeek":
            this.calculateRangeDay(-1, "weeks");
            await this.getDataChart();
            await this.generateDataProbabilityGraph();
            break;
          case "btnMonth":
            this.calculateRangeDay(-1, "months");
            await this.getDataChart();
            await this.generateDataProbabilityGraph();

            break;

          default:
            break;
        }
      }
    },

    async generateDataProbabilityGraph() {
      const index = this.samplingPoint?.parameters?.findIndex((_) => _.code == "TMOA");

      if (index != undefined && index >= 0 && this.samplingPoint?.parameters) {
        const validationFilter = {
          samplingPointId: [this.samplingPoint?.id],
          parameterId: [this.samplingPoint.parameters[index]?.id],
          date: [this.startDateCalculate, this.endDateCalculate],
        } as ValidationFilter_2;

        const estimation_history: any[] = await getPaginatedEstimations(validationFilter);

        const tmoas = sortByTypeReverse([...(await this.getTmoaBySamplingPoint())]);
        this.values = tmoas.map(
          (tmoa) =>
            ({
              tmoa: `${tmoa.value}`,
              name: tmoa.name ? tmoa.name + ` (${tmoa.value})` : `${tmoa.value}`,
              count: 0,
              value: 0,
            } as TmoaGraph)
        ) as TmoaGraph[];

        this.dataChartTmoa = [];
        let previousValue = this.samplingPoint?.numTmoa;
        estimation_history.forEach((objeto, index) => {
          // Convertimos dateTime si existe
          const dateTime = objeto.dateTime ? new Date(objeto.dateTime).getTime() : undefined;

          // Buscamos el nombre o valor correspondiente en tmoas
          const foundTmoa = tmoas.find((tmoa) => tmoa.value === (objeto.value as number));
          const name = foundTmoa?.name ? foundTmoa.name : `${foundTmoa?.value}`;

          if (dateTime) {
            // Creamos el objeto DataChart
            const dataChartItem: DataChart = {
              dateTime: dateTime,
              value: objeto.value,
              name: name,
              fileName: objeto.fileName,
            };

            if (
              this.samplingPoint &&
              this.samplingPoint.numTmoa &&
              objeto.value === this.samplingPoint.numTmoa
            ) {
              //- Puntos de color rojo
              dataChartItem.bulletSettings = { fill: am5.color(0xf37878) };
              //- Linea de color rojo
              dataChartItem.strokeSettings = { stroke: am5.color(0xf37878) };
            } else {
              //- Puntos de color rojo
              dataChartItem.bulletSettings = { fill: am5.color(0x3d80d1), opacity: 0 };
              if (this.dataChartTmoa && this.dataChartTmoa.length > 0) {
                //- El resto de lineas
                this.dataChartTmoa[index - 1].strokeSettings = { stroke: am5.color(0x6794dc) };
              }
            }

            previousValue = objeto.value;

            // Añadimos el objeto al array
            this.dataChartTmoa?.push(dataChartItem);
          }
        });

        estimation_history.map((estimation) => {
          if (estimation.es_value) {
            const index = this.values.findIndex(
              (element) => element.tmoa === `${estimation.es_value}`
            );
            if (index >= 0) {
              this.values[index].count += 1;
              this.values[index].value = Math.round(
                (this.values[index].count * 100) / estimation_history.length
              );
            }
          }
        });
      }
    },
    /**
     * Grafica datos fisicoquimicos
     */
    async getDataChart() {
      const validationFilter = {
        samplingPointId: [this.samplingPoint?.id],
        parameterId: [this.parameter?.id],
        date: [this.startDateCalculate, this.endDateCalculate],
      } as ValidationFilter_2;

      const estimation_history: Estimation[] = await getPaginatedEstimations(validationFilter);

      this.dataChart = estimation_history.map(
        (objeto) =>
          ({
            dateTime: objeto.dateTime ? new Date(objeto.dateTime).getTime() : undefined,
            value: objeto.value,
            unit: this.parameter?.unit,
            name: "",
            fileName: objeto?.fileName,
          } as DataChart)
      ) as DataChart[];
      console.log("data chart", this.dataChart);
    },

    /**
     * Escoge un parámetro para mostrar por defecto la gráfica.
     */
    async changeInitialParameter() {
      if (this.samplingPoint) {
        if (this.samplingPoint.parameters && this.samplingPoint.parameters.length > 0) {
          this.parameter = this.orderParameterByName(this.samplingPoint.parameters)[0];
          if (
            this.samplingPoint.parameters[0].code === "TMOA" &&
            this.samplingPoint.parameters.length > 1
          ) {
            this.parameter = this.orderParameterByName(this.samplingPoint.parameters)[1];
          }
        }
      }
    },

    /**
     * Recupera la imagen con los parámetros.
     */
    async updateLastImage() {
      if (this.samplingPoint && this.samplingPoint.id) {
        const response = await getLastImageWithParameters(this.samplingPoint.id);
        this.lastImage = response?.image;
        if (this.lastImage) {
          this.lastImage.imageEstimation = removeDuplicate(response?.parameters);
        }
      }
    },

    /**
     * Mostrar los parámetros que son visibles
     * @param samplingPointParameter
     */

    filterByParameterVisibility(samplingPointParameter: Parameter) {
      const parameterVisibility = this.samplingPoint?.parameters?.map((param: Parameter) => {
        return { name: param.name, visible: true };
      });
      return parameterVisibility
        ? parameterVisibility.find((_) => _.name == samplingPointParameter.name)?.visible
        : false;
    },

    async getTmoaBySamplingPoint() {
      const filter: Filter = {
        fields: {
          "tmoa.samplingPointId": { operator: "equal", value: this.samplingPoint?.id },
        },
      };
      return getTmoa(filter);
    },

    mapParameterToResult(parameter: Parameter) {
      return {
        name: "",
        parameter: parameter,
        range: "",
        value: undefined,
        precision: 0,
        quality: "",
      } as Estimation;
    },

    isInclude(code: string) {
      const parameters = this.lastImage?.imageEstimation as Estimation[] | undefined;
      if (parameters) {
        for (const p of parameters) {
          if (
            p.parameter &&
            p.parameter.name &&
            p.value !== undefined &&
            p.parameter.code &&
            p.parameter?.code === code
          ) {
            return { value: p.value, trend: p.trend, percent: p.percent };
          }
        }
      }
      return undefined;
    },
  },
  watch: {
    parameter: async function () {
      await this.getDataChart();
      await this.generateDataProbabilityGraph();
    },
  },
});
