import { Parameter } from "./../types/parameter.type";
import { Estimation } from "@/types/estimation.type";

export const formatParameter = (parameter: Parameter): string => {
  let name = "";
  if (parameter.name) {
    if (parameter.name.length > 15) {
      if (parameter.abbreviation) {
        name = parameter.abbreviation;
      } else {
        name = parameter.name.substring(0, 21);
      }
    } else {
      name = parameter.name;
    }
  }
  return name;
};

export const formatParameterName = (
  magnitude: string,
  parameters: Parameter[]
): string | undefined => {
  let name = "" as string | undefined;
  if (parameters) {
    for (const p of parameters) {
      if (p.code === magnitude) {
        if (p.name && p.name?.length > 15) {
          name = p.code;
        } else {
          name = p.name;
        }
      }
    }
  }
  if (name) {
    return name;
  }
};

interface ParameterTable extends Estimation {
  name?: string;
  colour?: string;
  tmoa?: string;
}

export const sortByCode = (array: ParameterTable[]) => {
  const order = ["TMOA", "NIV", "TU", "SS", "CE", "RE", "DQ", "OD", "PH", "NH"];
  array?.sort((a, b) => {
    const indexA = order.indexOf(a?.parameter?.code || "");
    const indexB = order.indexOf(b?.parameter?.code || "");

    // Comprobar si a.code o b.code no están en el orden personalizado
    const isAOutsideOrder = indexA === -1;
    const isBOutsideOrder = indexB === -1;

    if (isAOutsideOrder && isBOutsideOrder) {
      // Ambos están fuera del orden personalizado, mantener el orden original
      return 0;
    } else if (isAOutsideOrder) {
      // Solo a está fuera del orden personalizado, colocar a al final
      return 1;
    } else if (isBOutsideOrder) {
      // Solo b está fuera del orden personalizado, colocar b al final
      return -1;
    } else {
      // Ambos están en el orden personalizado, comparar según el índice
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    }
  });
};
