
import { defineComponent, PropType } from "vue";
import { getSamplingPointImages } from "@/service/image.service";
import SamplingPointImageComponent from "./SamplingPointImage.component.vue";
import { Image } from "@/types/Image.type";
import ObserverComponent from "../Observer.component.vue";
import { Parameter } from "@/types/parameter.type";
import { getColourById } from "@/service/colour.service";
import { Estimation } from "@/types/estimation.type";
import { removeDuplicate } from "@/utils/estimation.util";

interface ImageData extends Image {
  title?: string;
  parameter: Parameter;
}
interface ImageColour extends Image {
  colourCode?: number;
}
export default defineComponent({
  components: {
    ObserverComponent,
    SamplingPointImageComponent,
  },
  props: {
    samplingPoint: {
      type: Object,
      required: false,
      immediate: true,
      deep: true,
    },
    title: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      images: [] as ImageColour[],
      hasMore: true,
      colourCode: undefined as number | undefined,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getSamplingPointName() {
      if (this.images.length > 0 && this.images[0].samplingPoint) {
        return this.images[0].samplingPoint.name;
      }
      return "";
    },
    async showMore() {
      // - get result for current page
      const rowPerPage = 10;
      const { data, totalRows, currentPage } = await getSamplingPointImages(
        this.id,
        this.currentPage,
        rowPerPage
      );

      this.currentPage = currentPage;

      this.currentPage++;

      // - concat result array
      this.images = [...this.images, ...data];

      for (const image of this.images) {
        const imageEstimation = removeDuplicate(image.imageEstimation as Estimation[]);
        if (imageEstimation && typeof image.id === "number") {
          // Verificar que image.id sea un número
          for (const est of imageEstimation) {
            const object = est;
            if (object instanceof Object) {
              const estimation = object as Estimation;
              if (
                estimation.parameter?.name === "Color" &&
                estimation.value &&
                image.samplingPoint?.id
              ) {
                const colour = await getColourById(estimation.value, image.samplingPoint?.id);
                image.colourCode = colour.code;
              }
            }
          }
        }
      }

      // - check if exist more result
      if (totalRows <= this.currentPage * rowPerPage) {
        this.hasMore = false;
      }
    },
  },
});
