
import { defineComponent } from "vue";
import { getInfoEstimationYesterday } from "@/service/infoEstimationsYesterday";
import { InfoEstimationYesterday } from "@/types/infoEstimationYesterday";
import { Parameter } from "@/types/parameter.type";
import { Estimation } from "@/types/estimation.type";
import { ElRowClassNameParams } from "@/types/element.type";
import { dateFormatDate, formatDate } from "@/utils/date.utils";
import { any } from "@amcharts/amcharts5/.internal/core/util/Array";
import { getsamPointInfoEstimation } from "@/service/samplingpoint.service";
import { formatParameter } from "@/utils/parameter.util";
import moment from "moment";

export default defineComponent({
  props: {
    data: {
      type: Object as () => Estimation[],
      required: true,
    },
  },
  data() {
    return {
      selectedItemsId: [] as number[],
      estimationsYesterday: [] as InfoEstimationYesterday[],
      estimationsInfoCalculated: [] as InfoEstimationYesterday[],
      dataView: [] as any,
      dateYesterday: "" as string,
      startDatePicker: "" as unknown as Date | undefined,
      endDatePicker: "" as unknown as Date | undefined,
      startDate: "" as unknown as Date | undefined,
      endDate: "" as unknown as Date | undefined,
      startDateCalculate: new Date(),
      endDateCalculate: new Date(),
      infoYerterday: true,
    };
  },
  async mounted() {
    let dataTimeLastStimation24h = new Date();
    dataTimeLastStimation24h.setDate(dataTimeLastStimation24h.getDate() - 1);
    let startDate = new Date(dataTimeLastStimation24h.setHours(0, 0, 0, 0));
    let endtDate = new Date(dataTimeLastStimation24h.setHours(23, 59, 59, 999));

    this.startDatePicker = startDate;
    this.endDatePicker = endtDate;

    this.estimationsYesterday = await getInfoEstimationYesterday(
      Number(this.$route.query.samplingPoint)
    );

    this.prepareDataShow(this.data, this.estimationsYesterday);
  },
  methods: {
    prepareDataShow(paramsList: Estimation[], infoEstimation: InfoEstimationYesterday[]) {
      // console.log("algo");
      if (infoEstimation.length < 1) {
        console.log("NO DATA");
      } else {
        let mostrar = paramsList.map((parametro) => {
          //informacion de paremtro
          let filtro = infoEstimation.filter(
            (info) => info.parameter.id === parametro.parameter?.id
          );
          if (filtro.length != 0) {
            return filtro.map((element) => {
              this.dateYesterday = formatDate(element.date);
              return {
                parameter: formatParameter(element.parameter),
                maximo: element.maxValue ? element.maxValue : "-",
                minimo: element.minValue ? element.minValue : "-",
                media: element.averageValue ? element.averageValue : "-",
                moda: element.modeValue ? element.modeValue : "-",
              };
            });
          } else {
            return [
              {
                parameter: parametro.parameter?.name,
                maximo: 0,
                minimo: 0,
                media: 0,
                moda: 0,
              },
            ];
          }
        });
        // console.log("DATAVIEW mostrar =", mostrar)
        this.dataView = mostrar.map((elemetos) => {
          return {
            parameter: elemetos[0].parameter,
            maximo: elemetos[0].maximo != 0 ? elemetos[0].maximo : "-",
            minimo: elemetos[0].minimo != 0 ? elemetos[0].minimo : "-",
            media: elemetos[0].media != 0 ? elemetos[0].media : "-",
            moda: elemetos[0].moda != 0 ? elemetos[0].moda : "-",
          };
        });

        // Quitar de la lista matriz de agua
        // const index = this.dataView.findIndex(
        //   (data: any) => data.parameter.toLowerCase() === "matriz de agua"
        // );
        // this.dataView = this.dataView.slice(0, index).concat(this.dataView.slice(index + 1));
        // console.log("DATAVIEW =", this.dataView);
      }
    },
    tableRowClassName(params: ElRowClassNameParams<Parameter>): string {
      return params.row && params.row.active ? "" : "disable-row";
    },

    removeBlueClass() {
      var blueBtn = document.getElementsByClassName("blue");
      if (blueBtn[0]) {
        blueBtn[0].className = blueBtn[0].className.replace("blue", "");
      }
    },

    addBlueClass(idBtn: string) {
      const btnDate = document.getElementById(idBtn);
      btnDate?.classList.add("blue");
    },

    clickBtn(type: string) {
      this.removeBlueClass();
      this.addBlueClass(type);
      this.modifyTable(type);
    },

    async modifyTable(type: string, startDate?: Date | undefined, endDate?: Date | undefined) {
      this.infoYerterday = false;
      let datosEstimaciones: InfoEstimationYesterday[] = [];
      if (type === "" && startDate && endDate) {
        datosEstimaciones = await getsamPointInfoEstimation(
          Number(this.$route.query.samplingPoint),
          startDate,
          endDate
        );
        await this.prepareDataShow(this.data, datosEstimaciones);
      } else {
        switch (type) {
          case "btnDayInfoEstimation":
            // console.log("24horas")
            this.calculateRangeDay(-24, "h");
            datosEstimaciones = await getsamPointInfoEstimation(
              Number(this.$route.query.samplingPoint),
              this.startDateCalculate,
              this.endDateCalculate
            );
            await this.prepareDataShow(this.data, datosEstimaciones);
            break;
          case "btnWeekInfoEstimation":
            // console.log("semana")
            this.calculateRangeDay(-1, "weeks");
            datosEstimaciones = await getsamPointInfoEstimation(
              Number(this.$route.query.samplingPoint),
              this.startDateCalculate,
              this.endDateCalculate
            );
            await this.prepareDataShow(this.data, datosEstimaciones);
            break;
          case "btnMonthInfoEstimation":
            // console.log("mes");
            this.calculateRangeDay(-1, "months");
            datosEstimaciones = await getsamPointInfoEstimation(
              Number(this.$route.query.samplingPoint),
              this.startDateCalculate,
              this.endDateCalculate
            );
            await this.prepareDataShow(this.data, datosEstimaciones);
            break;
          default:
            break;
        }
      }
    },

    calculateRangeDay(
      time: moment.DurationInputArg1,
      unit: moment.unitOfTime.DurationConstructor | undefined
    ) {
      this.endDateCalculate = new Date();
      this.startDateCalculate = moment(this.endDateCalculate).add(time, unit).toDate();
      this.startDatePicker = this.startDateCalculate;
      this.endDatePicker = this.endDateCalculate;
    },
  },
  watch: {
    startDate: async function () {
      this.startDatePicker = this.startDate;
    },
    startDatePicker: async function () {
      await this.modifyTable("", this.startDatePicker, this.endDatePicker);
    },
    endDatePicker: async function () {
      await this.modifyTable("", this.startDatePicker, this.endDatePicker);
    },
  },
});
