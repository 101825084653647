
import { defineComponent, ref } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ListAutoDispose } from "@amcharts/amcharts5/.internal/core/util/List";
import { getImageByFile, getLastImagesByDate } from "@/service/image.service";
import SamplingPointImageComponent from "./SamplingPointImage.component.vue";
import { Image } from "@/types/Image.type";
import { ElMessage } from "element-plus";
import { Color } from "@amcharts/amcharts5/.internal/core/util/Color";
import { Pagination } from "@/types/pagination.type";
import am5lang_es_ES from "@amcharts/amcharts5/locales/es_ES";
import { i18n } from "@/plugins/i18n.plugin";

interface DataChart {
  dateTime: number;
  value: number;
  name: string;
  unit?: string;
  fileName?: string;
  bulletSettings?: { fill: Color };
  strokeSettings?: { stroke: Color };
}

interface DataContext {
  value: number;
}

export default defineComponent({
  components: {
    SamplingPointImageComponent,
  },
  setup() {
    const chart = "" as unknown as am5xy.XYChart;
    // Leyenda
    const legend = "" as unknown as am5.Legend;
    // Id grafica
    const chartId = "chartdiv";
    // Datos de las estimaciones para graficar
    const series = "" as unknown as am5xy.LineSeries;
    // Datos de las tmoas para graficar
    const seriesTmoa = "" as unknown as am5xy.LineSeries;
    // Datos de las estimaciones para los scrolls
    const sbseries = "" as unknown as am5xy.LineSeries;
    // Visibilidad del modal de imagen
    const visible = ref(false);

    const hasData = ref(true);

    return { chart, legend, chartId, series, seriesTmoa, sbseries, visible, hasData };
  },
  data() {
    return {
      // Fechas y horas dataPicker
      startDatePicker: "" as unknown as Date | undefined,
      endDatePicker: "" as unknown as Date | undefined,

      lastImage: undefined as Image | undefined,
      fileNames: [] as unknown as string[],
      estData: undefined as any,
      currentIndex: undefined as number | undefined,
      currentIndexTmoa: undefined as number | undefined,
      isParameter: undefined as boolean | undefined,
      maxValue: undefined as number | undefined,
      minValue: undefined as number | undefined,
    };
  },
  props: {
    samplingPoint: { type: Object, required: false, immediate: true, deep: true },
    parameter: { type: Object, required: false, immediate: true, deep: true },
    estimation: {
      type: Object as () => DataChart[],
      required: false,
    },
    tmoa: {
      type: Object as () => DataChart[],
      required: false,
    },
    startDate: { type: Date, required: false, deep: true },
    endDate: { type: Date, required: false, deep: true },
  },
  mounted() {
    this.$nextTick(async () => {
      this.updateChartData();
    });
  },
  methods: {
    updateChartData() {
      if (this.estimation && this.estimation.length > 0) {
        this.hasData = true;
        // Cargar gráfica
        this.fillData(this.estimation);
      } else {
        this.hasData = false;
        console.log("No hay datos para el periodo");
      }
    },
    addScrollbar(chart: am5xy.XYChart, root: am5.Root, estimation: DataChart[]) {
      // add scrollbar
      var scrollbarX = chart.set(
        "scrollbarX",
        am5xy.XYChartScrollbar.new(root, {
          orientation: "horizontal",
          height: 30,
        })
      );

      var scrollbarY = chart.set(
        "scrollbarY",
        am5.Scrollbar.new(root, { orientation: "vertical", width: 25 })
      );

      scrollbarY?.get("background")?.setAll({
        strokeOpacity: 0,
      });
      var sbxAxis = scrollbarX.chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          groupData: false,
          visible: false,
          baseInterval: { timeUnit: "millisecond", count: 1 },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: false,
          }),
        })
      );

      var yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.grid.template.set("forceHidden", false);

      var sbyAxis = scrollbarX.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer,
        })
      );

      this.sbseries = scrollbarX.chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: sbxAxis,
          yAxis: sbyAxis,
          valueYField: "value",
          valueXField: "dateTime",
        })
      );
      this.sbseries.fills.template.setAll({
        fillOpacity: 0.6,
        visible: true,
        strokeOpacity: 0,
      });

      this.sbseries.data.setAll(estimation);
    },

    /**
     * Generar gráfica
     * @param estimation
     */
    fillData(estimation: DataChart[]) {
      let root = am5.Root.new("chartdiv");
      root.locale = am5lang_es_ES;
      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      this.chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          paddingLeft: 0,
        })
      );

      let cursor = this.chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", true);
      cursor.lineY.set("visible", false);

      var easing = am5.ease.linear;
      this.chart.get("colors")?.set("step", 2);

      // Create axes
      const xAxis = this.chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0,
          groupData: false,
          baseInterval: {
            timeUnit: "minute",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
          }),
          //tooltip: am5.Tooltip.new(root, {}),
        })
      );

      // Establecer formato gráfica
      // root.numberFormatter.set("numberFormat", "####.##");

      this.createAxisYEstimation(root, xAxis, estimation);
      this.createAxisYTmoa(root, xAxis);

      this.createLegend(root);

      this.series.appear(1000);
      this.chart.appear(1000, 100);
    },

    async getImage(ev: any, type: string) {
      if (type == "estimation") {
        this.isParameter = true;
      }

      try {
        const dataContext = ev.target.dataItem?.dataContext as DataChart;
        this.fileNames = (type === "estimation" ? this.estimation ?? [] : this.tmoa ?? [])
          .map((item: DataChart) => item.fileName)
          .filter((fileName): fileName is string => fileName !== undefined);

        const index = this.fileNames.findIndex((img) => img === dataContext.fileName);

        if (index !== -1) {
          if (type === "estimation") {
            this.currentIndex = index;
          } else {
            this.currentIndexTmoa = index;
          }
          getImageByFile(this.fileNames[index]).then((image) => {
            this.lastImage = image;
            this.visible = true;
          });
        } else {
          ElMessage.warning("No se encontró la imagen correspondiente en la lista.");
        }
      } catch (error) {
        ElMessage.error("Ocurrió un error al intentar cargar las imágenes.");
      }
    },
    /**
     * Crear leyenda de la gráfica
     * @param root
     */
    createLegend(root: am5.Root) {
      console.log(this.parameter?.name);
      this.legend = this.chart.children.push(
        am5.Legend.new(root, {
          x: am5.percent(33),
          centerX: am5.percent(33),
          y: am5.p100,
          layout: root.horizontalLayout,
        })
      );
      this.legend.data.setAll(this.chart.series.values);
      if (
        this.parameter?.name.toLowerCase() !== "matriz de agua" &&
        this.estimation &&
        this.estimation.length > 0
      ) {
        if (root.container.children.length > 1) {
          root.container.children.pop();
        }
        let data = am5.Container.new(root, {
          layout: root.horizontalLayout,
          x: am5.percent(70),
          centerX: am5.percent(70),
          y: am5.percent(101),
          centerY: am5.percent(101),
          paddingTop: 20,
          paddingLeft: 20,
          layer: 100,
        });

        let legendContainer = root.container.children.push(data);

        this.createFixedLegend(root);
      }
    },
    createTmoaLegend(root: am5.Root) {
      this.legend.data.setAll([]);
      let data = am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: am5.percent(50),
        centerX: am5.percent(50),
        y: am5.percent(101),
        centerY: am5.percent(101),
        paddingTop: 20,
        paddingLeft: 20,
        layer: 100,
      });

      if (root.container.children.length > 1) {
        root.container.children.pop();
      }
      let legendContainer = root.container.children.push(data);

      function createLegendItemLine(name: string, color: am5.Color) {
        let legendItem = am5.Container.new(root, {
          layout: root.horizontalLayout,
          marginBottom: 0,
          centerY: am5.percent(50),
        });

        let colorLine1 = am5.Rectangle.new(root, {
          width: 20,
          height: 3,
          fill: color,
          marginRight: 10,
          centerY: am5.percent(50),
        });

        let label = am5.Label.new(root, {
          text: name,
          fontSize: 14,
          marginRight: 10,
          centerY: am5.percent(50),
        });

        legendItem.children.push(colorLine1);
        legendItem.children.push(label);

        return legendItem;
      }

      // Add legend items
      legendContainer.children.push(createLegendItemLine(`Matriz de Agua`, am5.color(0xadd8e6)));

      // Add Anomalías
      let anomaliasItem = this.createLegendItemDot(root, "Anomalía", am5.color(0xf37878));
      legendContainer.children.push(anomaliasItem);
    },

    createFixedLegend(root: am5.Root) {
      this.legend.data.setAll([]);
      let data = am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: am5.percent(90),
        centerX: am5.percent(100),
        y: am5.percent(101),
        centerY: am5.percent(101),
        paddingTop: 20,
        paddingLeft: 20,
      });

      if (root.container.children.length > 1) {
        root.container.children.pop();
      }
      let legendContainer = root.container.children.push(data);

      function createLegendItemLine(name: string, color: am5.Color) {
        let legendItem = am5.Container.new(root, {
          layout: root.horizontalLayout,
          marginBottom: 0,
          centerY: am5.percent(90),
        });

        let colorLine1 = am5.Rectangle.new(root, {
          width: 20,
          height: 3,
          fill: color,
          marginLeft: 50,
          centerY: am5.percent(90),
        });

        let label = am5.Label.new(root, {
          text: name,
          fontSize: 14,
          marginLeft: 50,
          centerY: am5.percent(90),
        });

        legendItem.children.push(colorLine1);
        legendItem.children.push(label);

        return legendItem;
      }

      // Add Anomalías
      let anomaliasItem = this.createLegendItemDot(root, "Anomalía", am5.color(0xf37878));
      legendContainer.children.push(anomaliasItem);
      let minItem = this.createLegendItemDot(
        root,
        `Mín: ${this.minValue ? i18n.global.n(this.minValue, "fixed") : "-"}`,
        am5.color(0x50c878)
      );
      legendContainer.children.push(minItem);

      let maxItem = this.createLegendItemDot(
        root,
        `Max: ${this.maxValue ? i18n.global.n(this.maxValue, "fixed") : "-"}`,
        am5.color(0xffa500)
      );
      legendContainer.children.push(maxItem);
    },
    createLegendItemDot(root: am5.Root, name: string, color: am5.Color) {
      let legendItem = am5.Container.new(root, {
        layout: root.horizontalLayout,
        marginBottom: 0,
        centerY: am5.percent(50),
      });

      let colorDot = am5.Circle.new(root, {
        radius: 5,
        fill: color,
        marginRight: 10,
        centerY: am5.percent(50),
      });

      let label = am5.Label.new(root, {
        text: name,
        fontSize: 14,
        marginRight: 10,
        centerY: am5.percent(50),
      });

      legendItem.children.push(colorDot);
      legendItem.children.push(label);
      return legendItem;
    },
    /**
     *
     * @param root
     * @param xAxis
     * @param estimation
     */
    createAxisYEstimation(
      root: am5.Root,
      xAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
      estimation: DataChart[]
    ) {
      // --AQUI export
      var yRenderer_1 = am5xy.AxisRendererY.new(root, {
        opposite: false,
      });
      var yAxis = this.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer_1,
        })
      );

      if (this.chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set(
          "syncWithAxis",
          (this.chart.yAxes as ListAutoDispose<am5xy.ValueAxis<am5xy.AxisRenderer>>).getIndex(0)
        );
      }

      this.series = this.chart.series.push(
        am5xy.LineSeries.new(root, {
          name: `${this.parameter?.name ? this.parameter?.name : "Sin Datos"}`,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "dateTime",
        })
      );

      yRenderer_1.grid.template.set("strokeOpacity", 0.05);
      yRenderer_1.labels.template.set("fill", this.series.get("fill"));
      // Eje y
      yRenderer_1.setAll({
        stroke: this.series.get("fill"), //Color del relleno
        strokeOpacity: 1,
        opacity: 1,
      });

      this.series.bullets.push((root, _, dataItem) => {
        // Obtener el valor actual del bullet
        let dataContext = dataItem.dataContext as DataContext;
        let value = dataContext.value;

        let opacity = 0;
        let circleColor = this.series.get("fill");
        let radius = 3;
        if (value === this.minValue) {
          circleColor = am5.color(0x50c878);
          opacity = 1;
        } else if (value === this.maxValue) {
          circleColor = am5.color(0xffa500);
          opacity = 1;
        }

        let bulletCircle = am5.Circle.new(root, {
          radius: radius,
          fill: circleColor,
          opacity: opacity,
          cursorOverStyle: "pointer",
          tooltipHTML: `
            <div style="display: flex; justify-content: center; align-items: center; height: 100%; text-align: center;">
                <div>
                    <div>{valueX.formatDate('dd/MM/yyyy HH:mm')}</div>
                    <div style="font-weight: bold;">Valor: {valueY}</div>
                </div>
            </div>
          `,
        });

        // Crear un círculo invisible para el evento click
        let invisibleCircle = am5.Circle.new(root, {
          radius: 5,
          fill: circleColor,
          opacity: 0,
          cursorOverStyle: "pointer",
        });
        invisibleCircle.events.on("click", (event: any) => {
          this.getImage(event, "estimation");
        });

        let container: any = am5.Container.new(root, {});
        container.children.push(invisibleCircle);
        container.children.push(bulletCircle);
        container.events.on("click", (event: any) => {
          this.getImage(event, "estimation");
        });

        return am5.Bullet.new(root, {
          sprite: container,
        });
      });

      this.series.fills.template.setAll({ fillOpacity: 0.3, visible: true });
      if (this.parameter?.name.toLowerCase() !== "matriz de agua") {
        this.series.data.setAll(estimation);
      }
      return yAxis;
    },

    createAxisYTmoa(root: am5.Root, xAxis: am5xy.DateAxis<am5xy.AxisRenderer>) {
      if (
        this.parameter?.name.toLowerCase() === "matriz de agua" &&
        this.tmoa &&
        this.tmoa.length >= 0
      ) {
        return;
      }

      //- Datos tmoa
      let yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: true,
      });
      let yAxis_2 = this.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: this.samplingPoint?.numTmoa != 0 ? this.samplingPoint?.numTmoa + 0.5 : 6.5,
          maxPrecision: 0,
          strictMinMax: true,
          renderer: yRenderer,
        })
      );

      if (this.chart.yAxes.indexOf(yAxis_2) > 0) {
        yAxis_2.set(
          "syncWithAxis",
          (this.chart.yAxes as ListAutoDispose<am5xy.ValueAxis<am5xy.AxisRenderer>>)?.getIndex(0)
        );
      }

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      this.seriesTmoa = this.chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Matriz de agua",
          xAxis: xAxis,
          yAxis: yAxis_2,
          valueYField: "value",
          valueXField: "dateTime",

          // tooltip: am5.Tooltip.new(root, {
          //   pointerOrientation: "horizontal",
          //   labelText: "{valueY}",
          // }),
        })
      );

      //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      this.seriesTmoa.strokes.template.setAll({ strokeWidth: 1 });
      this.seriesTmoa.strokes.template.set("templateField", "strokeSettings");

      yRenderer.grid.template.set("strokeOpacity", 0.05);

      yRenderer.labels.template.set("fill", this.seriesTmoa.get("fill"));
      yRenderer.setAll({
        stroke: this.seriesTmoa.get("fill"),
        strokeOpacity: 1,
        opacity: 1,
      });

      this.seriesTmoa.set("fill", am5.color("#00ff00"));
      this.seriesTmoa.bullets.push(() => {
        let circle = am5.Circle.new(root, {
          radius: 3,
          templateField: "bulletSettings",
          strokeWidth: 3,
          cursorOverStyle: "pointer",
          tooltipText: "{valueX.formatDate('dd/MM/yyyy HH:mm:ss')} \n[bold]Valor: [/] {valueY} ",
        });

        // Circulo invisible para evento click
        let invisibleCircle = am5.Circle.new(root, {
          radius: 8,
          fill: am5.color(0x000000),
          opacity: 0,
          cursorOverStyle: "pointer",
        });

        invisibleCircle.events.on("click", (event: any) => {
          this.getImage(event, "tmoa");
        });
        circle.events.on("click", (event: any) => {
          this.getImage(event, "tmoa");
        });

        return am5.Bullet.new(root, {
          sprite: circle,
        });
      });

      if (this.estimation) {
        this.addScrollbar(this.chart, root, this.estimation);
      }

      if (this.tmoa) {
        this.seriesTmoa.data.setAll(this.tmoa);
      } else {
        this.seriesTmoa.data.setAll([] as DataChart[]);
      }

      return yAxis_2;
    },
    seeImages() {
      this.visible = true;
    },
    async nextImg() {
      let currentIndex, fileNames;

      if (this.isParameter) {
        currentIndex = this.currentIndex;
        fileNames = this.fileNames;
      } else {
        currentIndex = this.currentIndexTmoa;
        fileNames = this.fileNames;
      }
      if (currentIndex !== undefined && currentIndex < fileNames.length - 1) {
        currentIndex++;

        try {
          this.lastImage = await getImageByFile(fileNames[currentIndex]);

          if (this.isParameter) {
            this.currentIndex = currentIndex;
          } else {
            this.currentIndexTmoa = currentIndex;
          }
        } catch (error) {
          ElMessage.error("Ocurrió un error al cargar la siguiente imagen.");
        }
      } else {
        ElMessage.warning("Esta es la última imagen.");
      }
    },

    async prevImg() {
      let currentIndex, fileNames;

      if (this.isParameter) {
        currentIndex = this.currentIndex;
        fileNames = this.fileNames;
      } else {
        currentIndex = this.currentIndexTmoa;
        fileNames = this.fileNames;
      }
      if (currentIndex !== undefined && currentIndex > 0) {
        currentIndex--;

        try {
          this.lastImage = await getImageByFile(fileNames[currentIndex]);

          if (this.isParameter) {
            this.currentIndex = currentIndex;
          } else {
            this.currentIndexTmoa = currentIndex;
          }
        } catch (error) {
          console.error("Error al cargar la imagen anterior:", error);
          ElMessage.error("Ocurrió un error al cargar la imagen anterior.");
        }
      } else {
        ElMessage.warning("Esta es la primera imagen.");
      }
    },
  },
  watch: {
    estimation: async function () {
      if (this.estimation && this.estimation.length > 0) {
        this.minValue = Math.min(
          ...this.estimation
            .map((item) => item.value)
            .filter((value) => value !== null && value !== undefined)
        );
        this.maxValue = this.maxValue = Math.max(
          ...this.estimation
            .map((item) => item.value)
            .filter((value) => value !== null && value !== undefined)
        );
        if (!this.sbseries) {
          this.fillData(this.estimation);
        } else {
          if (this.parameter?.name.toLowerCase() !== "matriz de agua") {
            this.series.data.setAll(this.estimation);
            this.sbseries.data.setAll(this.estimation);

            this.chart.root;
            if (this.chart.root.container.children.length > 1) {
              this.chart.root.container.children.pop();
            }
            if (this.legend) {
              this.chart.children.removeValue(this.legend);
            }
            this.createLegend(this.chart.root);

            if (!this.chart.yAxes.getIndex(0)?.get("visible")) {
              this.chart.yAxes.getIndex(0)?.set("visible", true);
            }
            const renderer = this.chart.yAxes.getIndex(1)?.get("renderer") as am5xy.AxisRendererY;
            renderer.set("opposite", true);
          } else {
            this.series.data.setAll([] as DataChart[]);
            this.sbseries.data.setAll([] as DataChart[]);
            // this.legend.data?.setAll(this.chart.series.values);

            let root = this.chart.root;
            if (root?.container && root.container.children.length > 1) {
              root.container.children.pop();
            }
            if (this.legend) {
              this.chart.children.removeValue(this.legend);
            }
            this.createTmoaLegend(root);

            if (this.chart.yAxes.getIndex(0)?.get("visible")) {
              this.chart.yAxes.getIndex(0)?.set("visible", false);
            }
            const renderer = this.chart.yAxes.getIndex(1)?.get("renderer") as am5xy.AxisRendererY;
            renderer.set("opposite", false);
          }
          this.series.set("name", `${this.parameter?.name}`);
        }
      } else {
        this.minValue = undefined;
        this.maxValue = undefined;
        if (this.sbseries && this.series) {
          this.createFixedLegend(this.chart.root);
          this.series.data.setAll([] as DataChart[]);
          this.sbseries.data.setAll([] as DataChart[]);
          this.series.set("name", `${this.parameter?.name}`);
          if (this.legend) {
            this.legend.data?.setAll([] as DataChart[]);
          }

          ElMessage.warning(this.$t("No hay datos en el periodo seleccionado"));
        } else {
          let root = this.chart.root;
          if (root?.container && root.container.children.length > 1) {
            root.container.children.pop();
          }
          this.fillData([]);
        }
      }
    },

    tmoa: async function () {
      if (this.tmoa && this.tmoa.length > 0) {
        if (this.seriesTmoa) {
          this.seriesTmoa.data.setAll(this.tmoa);
          this.legend.data?.setAll(this.chart.series.values);
        }
      } else {
        if (this.seriesTmoa) {
          this.seriesTmoa.data.setAll([] as DataChart[]);
        }
      }
    },
    startDatePicker: async function () {
      this.updateChartData();
    },
    endDatePicker: async function () {
      this.updateChartData();
    },
  },
});
